@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --leme-color-brand-dark: #252859;
  --leme-color-brand-light: #00AEB7;
  --leme-color-backdrop: #21252990;

  --leme-color-gray-0: #FFFFFF;
  --leme-color-gray-1: #F8F9FA;
  --leme-color-gray-2: #E9ECEF;
  --leme-color-gray-3: #DEE2E6;
  --leme-color-gray-4: #CED4DA;
  --leme-color-gray-5: #ADB5BD;
  --leme-color-gray-6: #6C757D;
  --leme-color-gray-7: #495057;
  --leme-color-gray-8: #343A40;
  --leme-color-gray-9: #212529;

  --leme-color-green-1: #CDF4D7;
  --leme-color-green-2: #2DC653;
  --leme-color-green-3: #114B1F;

  --leme-color-yellow-1: #FEEEB8;
  --leme-color-yellow-2: #FDC921;
  --leme-color-yellow-3: #B68B02;

  --leme-color-red-1: #FCCFD5;
  --leme-color-red-2: #EF233C;
  --leme-color-red-3: #A20C1E;

  --leme-typography-family: "Montserrat", sans-serif;

  --leme-typography-weight-regular: 400;
  --leme-typography-weight-medium: 500;
  --leme-typography-weight-semibold: 600;

  --leme-typography-heading-1-regular: var(--leme-typography-weight-regular) 4rem/1.25 var(--leme-typography-family);
  --leme-typography-heading-1-medium: var(--leme-typography-weight-medium) 4rem/1.25 var(--leme-typography-family);
  --leme-typography-heading-1-semibold: var(--leme-typography-weight-semibold) 4rem/1.25 var(--leme-typography-family);

  --leme-typography-heading-2-regular: var(--leme-typography-weight-regular) 3rem/1.16 var(--leme-typography-family);
  --leme-typography-heading-2-medium: var(--leme-typography-weight-medium) 3rem/1.16 var(--leme-typography-family);
  --leme-typography-heading-2-semibold: 3rem/1.16 var(--leme-typography-family);

  --leme-typography-heading-3-regular: var(--leme-typography-weight-regular) 2.5rem/1.2 var(--leme-typography-family);
  --leme-typography-heading-3-medium: var(--leme-typography-weight-medium) 2.5rem/1.2 var(--leme-typography-family);
  --leme-typography-heading-3-semibold: var(--leme-typography-weight-semibold) 2.5rem/1.2 var(--leme-typography-family);

  --leme-typography-heading-4-regular: var(--leme-typography-weight-regular) 2rem/1.25 var(--leme-typography-family);
  --leme-typography-heading-4-medium: var(--leme-typography-weight-medium) 2rem/1.25 var(--leme-typography-family);
  --leme-typography-heading-4-semibold: var(--leme-typography-weight-semibold) 2rem/1.25 var(--leme-typography-family);

  --leme-typography-heading-5-regular: var(--leme-typography-weight-regular) 1.5rem/1.33 var(--leme-typography-family);
  --leme-typography-heading-5-medium: var(--leme-typography-weight-medium) 1.5rem/1.33 var(--leme-typography-family);
  --leme-typography-heading-5-semibold: var(--leme-typography-weight-semibold) 1.5rem/1.33 var(--leme-typography-family);

  --leme-typography-heading-6-regular: var(--leme-typography-weight-regular) 1.25rem/1.2 var(--leme-typography-family);
  --leme-typography-heading-6-medium: var(--leme-typography-weight-medium) 1.25rem/1.2 var(--leme-typography-family);
  --leme-typography-heading-6-semibold: var(--leme-typography-weight-semibold) 1.25rem/1.2 var(--leme-typography-family);

  --leme-typography-body-large-regular: var(--leme-typography-weight-regular) 1.125rem/1.33 var(--leme-typography-family);
  --leme-typography-body-large-medium: var(--leme-typography-weight-medium) 1.125rem/1.33 var(--leme-typography-family);
  --leme-typography-body-large-semibold: var(--leme-typography-weight-semibold) 1.125rem/1.33 var(--leme-typography-family);

  --leme-typography-body-regular: var(--leme-typography-weight-regular) 1rem/1.5 var(--leme-typography-family);
  --leme-typography-body-medium: var(--leme-typography-weight-medium) 1rem/1.5 var(--leme-typography-family);
  --leme-typography-body-semibold: var(--leme-typography-weight-semibold) 1rem/1.5 var(--leme-typography-family);

  --leme-typography-body-small-regular: var(--leme-typography-weight-regular) 0.875rem/1.71 var(--leme-typography-family);
  --leme-typography-body-small-medium: var(--leme-typography-weight-medium) 0.875rem/1.71 var(--leme-typography-family);
  --leme-typography-body-small-semibold: var(--leme-typography-weight-semibold) 0.875rem/1.71 var(--leme-typography-family);

  --leme-typography-body-extra-small-regular: var(--leme-typography-weight-regular) 0.75rem/1.33 var(--leme-typography-family);
  --leme-typography-body-extra-small-medium: var(--leme-typography-weight-medium) 0.75rem/1.33 var(--leme-typography-family);
  --leme-typography-body-extra-small-semibold: var(--leme-typography-weight-semibold) 0.75rem/1.33 var(--leme-typography-family);

  --leme-shadow: 0px 4.28px 17.11px 0px #00000008;

  --leme-rounded: 4px;
  --leme-rounded-md: 8px;
  --leme-rounded-lg: 12px;

  --leme-border: 1px solid var(--leme-color-gray-3);
}
