@media only screen and (min-width: 770px) {
  .PdfHighlighter {
    height: calc(100% - 56px);
    width: calc(100% - 120px);
    padding-top: 32px;
  }
}

@media only screen and (max-width: 769px) {
  .PdfHighlighter {
    width: calc(100% - 80px);
    height: 100%;
    margin: 56px 0;
    padding-bottom: 156px;
  }
}

.PdfHighlighter .textLayer ::selection {
  background-color: rgba(129, 106, 177, 0.7);
}

.PdfHighlighter .green .Highlight__part {
  background-color: #9EC3B7;
}

.PdfHighlighter .yellow .Highlight__part {
  background-color: #F0D894;
}

.PdfHighlighter .red .Highlight__part {
  background-color: #F1ACAE;
}

.PdfHighlighter .purple .Highlight__part {
  background-color: #C0ACF1;
}