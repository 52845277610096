.profileLinks {
  display: flex;
  gap: 1rem;
  align-items: center;
  font: var(--leme-typography-body-small-regular);
}

.navLink {
  color: var(--leme-color-gray-6);
  font: var(--leme-typography-body-small-medium);
}

.profileLink:hover,
.navLink:hover {
  color: var(--leme-color-gray-9);
  text-decoration: none;
}

.navLink {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 8px;
}

.searchClearButton {
  width: fit-content;
  right: 10px;
  top: 5px;
}

.clearButton {
  background: transparent;
  border: 0;
  color: var(--leme-color-gray-6);
  font: var(--leme-typography-body-small-medium);
}

.searchRoot {
  display: inline-grid;
  align-content: center;
  width: 100%;
}

.searchInput {
  border: 0;
  padding-left: 44px !important;
  padding-right: 44px !important;
  background: var(--leme-color-gray-1);
  color: var(--leme-color-gray-6);
  border-radius: var(--leme-rounded-md);
  height: 40px;
  font: var(--leme-typography-body-small-regular);
}

.searchInput::placeholder {
  color: var(--leme-color-gray-6);
}

.searchIcon,
.clearButton span {
  height: 18px;
  width: 18px;
}

.searchIcon {
  left: 15px;
  top: 10px;
}

.profileLink {
  color: var(--leme-color-gray-9);
  font: var(--leme-typography-body-small-medium);
  padding: 8px 16px;
  display: block;
  cursor: pointer;
}

.profileLink:hover {
  background-color: var(--leme-color-gray-1);
  text-decoration: none;
}

.dropdown {
  border: 1px solid var(--leme-color-gray-3);
  box-shadow: var(--leme-shadow);
  padding: 8px;
  border-radius: var(--leme-rounded-md);
}