*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  font-optical-sizing: auto;
}

body {
  margin: 0;
}

img,
picture,
video {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

#root {
  isolation: isolate;
}
